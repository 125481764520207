<template>
    <div class="login-bg">
        <div class="p-d-flex p-jc-center">
            <Card style="width: 25em" class="p-mt-5">
                <template #header>
                    <div class="p-d-flex p-jc-center p-mt-4">
                        <img alt="user header" src="assets/layout/images/neofase_logo_login.jpg" style="width: 18em" />
                    </div>
                </template>
                <!-- <template #title>
                    <div class="p-text-center">
                        <span>Seja bem vindo(a), entre com suas credenciais</span>
                    </div>
                </template> -->
                <template #content>
                    <erros-box :erros="erros"></erros-box>
                    <div class="p-formgrid p-grid p-fluid">
                        <!-- <div class="p-field p-col-12 p-mt-4">
                            <label>Instituição</label>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <font-awesome-icon icon="landmark" />
                                </span>
                                <InputText v-model="instituicao" @input="v$.instituicao.$touch()" placeholder="Instituição (sigla)" />
                            </div>
                            <small class="p-error" v-if="v$.instituicao.$error">Sigla de acesso da instituição é obrigatória</small>
                        </div> -->
                        <div class="p-field p-col-12">
                            <!-- <label>Usuário</label> -->
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-user"></i>
                                </span>
                                <InputText v-model="usuario" @input="v$.usuario.$touch()" placeholder="Usuário" />
                            </div>
                            <small class="p-error" v-if="v$.usuario.$error">Usuário é obrigatório</small>
                        </div>
                        <div class="p-field p-col-12 p-mt-2">
                            <!-- <label>Senha</label> -->
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-key"></i>
                                </span>
                                <InputText type="password" v-model="senha" @input="v$.senha.$touch()" @keypress.enter="recaptcha" placeholder="Senha" />
                            </div>
                            <small class="p-error" v-if="v$.senha.$error">Senha é obrigatória</small>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-inline-flex p-flex-column p-jc-center">
                        <Button label="Entrar" icon="pi pi-check" @click="recaptcha" class="btnLogin" />
                        <Button label="Recuperar Senha" icon="pi pi-key" @click="recuperarSenha()" class="btnLogin p-mt-3" />
                        <div class="p-mt-4 p-text-center">
                            <strong>v{{ versao }}</strong>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AuthServices from './AuthServices';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            usuario: '',
            senha: '',
            erros: [],
            versao: process.env.VUE_APP_VERSION,
        };
    },

    validations() {
        return {
            usuario: { required },
            senha: { required },
        };
    },

    methods: {
        async recaptcha() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            this.$store.dispatch('addRequest');
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.login(token);
        },

        login(token) {
            let loginDto = {
                usuario: this.usuario.toLowerCase(),
                senha: this.senha,
                token: token,
            };

            AuthServices.login(loginDto).then((response) => {
                if (response && response.success) {
                    this.$store.dispatch('login', response.data);
                    this.reset();
                    this.processarRota();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        processarRota() {
            if (this.$store.getters.usuario.aspl === 'True') {
                this.$router.push('/atualizarsenha');
            } else {
                this.$router.push('/');
            }
        },

        reset() {
            this.usuario = '';
            this.senha = '';
            this.erros = [];
            this.v$.$reset();
        },

        recuperarSenha() {
            this.$router.push('/recuperarsenha');
        },
    },
};
</script>
